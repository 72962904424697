<template>
  <div v-if="isLoading">
    <div class="text-center text-danger screen-center">
      <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
      <strong>Cargando...</strong>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-sm-5">
        <div class="breadcrumb">
          <h1>Modificar Venta
            <span class="h4 pl-2">
              N°: {{getCorrelativo}}
            </span>
          </h1>
        </div>
      </div>
      <transition name="fade">
        <div v-if="showCortinaInformativa"
         class="col-sm-7 navbar-reubicado">
          <div class="row">
            <div
              v-if="parseFloat(getPacienteToShow.tratamiento) !== 0
              && getPacienteToShow.tratamiento !== null
              && parseFloat(getPacienteToShow.anticipo) !== 0
              && getPacienteToShow.anticipo !== null"
              class="col-sm-3 pt-2 pb-1 bg-yellow-custom
              text-center border-right border-white">
              Total Tratamiento: <br>
              {{getPacienteToShow.tratamiento | numeral('0,0.00')}}
            </div>
            <div v-else class="col-sm-3"></div>
            <div
              v-if="parseFloat(getPacienteToShow.anticipo) !== 0
              && getPacienteToShow.anticipo !== null"
              class="col-sm-2 pt-2 pb-1 bg-yellow-custom text-center
              border-left border-right border-white">
              Tiene Anticipo: <br>
              {{getPacienteToShow.anticipo | numeral('0,0.00')}}
            </div>
            <div v-else class="col-sm-2"></div>
            <div class="col-sm-3 py-2 bg-sky-light text-white border-left border-white">
              Edad: {{getPacienteToShow.edad}} años
            </div>
            <div class="col-sm-4 py-2 bg-sky-light text-white">
              Cobertura: {{getPacienteToShow.cobertura}}
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div class="row mb-2">
      <div class="col-sm-12">
        <div class="card text-left">
          <div class="card-header">
            <div class="col-sm-5">
              <button type="submit"
                @click="guardarFormulario"
                class="btn bg-custom-green mr-2 rounded"
                :disabled="buttonDisabled"
              >
                Guardar
              </button>
              <router-link :to="{ name: 'facturacion-index' }">
                <button class="btn btn-white border border-light rounded">
                  Regresar
                </button>
              </router-link>
            </div>
          </div>
          <div class="card-body">
            <!-- contiene formulario para factura -->
            <FormFactura />
            <!-- Tabla y enlace Agregar Servicio-->
            <TableAndAddService />
            <!-- detalles finales de factura -->
            <DetalleFactura />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import moment from 'moment';
import FormFactura from '../components/TheFormFactura.vue';
import TableAndAddService from '../components/SectionTableAndAddService.vue';
import DetalleFactura from '../components/TheDetalleFactura.vue';
import { util } from '../../../plugins/util';

export default {
  name: 'edit',
  components: {
    FormFactura,
    TableAndAddService,
    DetalleFactura,
  },
  async created() {
    this.bloquearElementosInterfaz();
    await this.callEditApi(this.id);
    this.asignarfechaCreditoVencimiento();
    this.cargarInsigniasFormaPagoEdit();
    this.isLoading = false;
  },
  beforeDestroy() {
    this.resetState();
  },
  data() {
    return {
      isLoading: true,
      buttonDisabled: false,
      id: atob(this.$route.params.id),
    };
  },
  methods:
  {
    bloquearElementosInterfaz() {
      this.setModoEdicion(true);
      this.setShowDatoFactura(false);
      this.setDisabledStatusFactura(true);
      this.setDisabledDatePicker(true);
      this.setStatusComboMoneda(true);
    },
    redirect(path) {
      this.$router.push(path);
    },
    async guardarFormulario() {
      if (this.getPacienteSelected === null) {
        util.showNotify('Elija un Paciente', 'warn');
        return;
      }
      if (this.isEnabledFormaPago === false) {
        util.showNotify('Ingrese forma de pago!', 'warn');
        return;
      }
      if (this.checkSeguroEstado && parseFloat(this.totalSeguro) <= 0) {
        util.showNotify('Ingrese un servicio con seguro!', 'warn');
        return;
      }
      if (this.caja + this.banco + this.tarjeta > this.getSubTotal) {
        util.showNotify('el monto de pago es superior al subTotal', 'warn');
        return;
      }
      this.buttonDisabled = true;
      const UPDATE_OK = await this.callUpdateApi(this.id);
      if (UPDATE_OK) {
        this.redirect({ name: 'facturacion-index' });
      }
      this.buttonDisabled = false;
    },
    resetState() {
      this.resetFacturacion();
      this.resetFormFactura();
      this.resetTableAndAddService();
      this.resetAddServiceModal();
      this.resetFormaPagoModal();
      this.resetFormaPagoFacturaTercero();
      this.resetDetalleFactura();
    },
    asignarfechaCreditoVencimiento() {
      const year = this.getDatePicker.getFullYear();
      const month = this.getDatePicker.getMonth();
      const day = this.getDatePicker.getDate();
      const NEW_DAY = parseInt(day, 10) + parseInt(this.getInputDiasCredito, 10);
      const FechaNueva = new Date(year, month, NEW_DAY);
      this.setFechaCreditoVencimiento(moment(FechaNueva).format('DD/M/YYYY'));
    },
    ...mapMutations('facturacion', [
      'setShowCortinaInformativa',
      'setModoEdicion',
    ]),
    ...mapMutations('formFactura', [
      'setDisabledStatusFactura',
      'setShowDatoFactura',
      'setDisabledDatePicker',
      'setStatusComboMoneda',
    ]),
    ...mapMutations('formaPagoModal', [
      'setFechaCreditoVencimiento',
    ]),
    ...mapActions('facturacion', [
      'callEditApi',
      'callUpdateApi',
      'resetFacturacion',
    ]),
    ...mapActions('formFactura', [
      'resetFormFactura',
    ]),
    ...mapActions('tableAndAddService', [
      'resetTableAndAddService',
    ]),
    ...mapActions('addServiceModal', [
      'resetAddServiceModal',
    ]),
    ...mapActions('formaPagoModal', [
      'resetFormaPagoModal',
    ]),
    ...mapActions('formaPagoFacturaTercero', [
      'resetFormaPagoFacturaTercero',
    ]),
    ...mapActions('detalleFactura', [
      'cargarInsigniasFormaPagoEdit',
      'resetDetalleFactura',
    ]),
  },
  computed:
  {
    ...mapFields('formFactura', [
      'checkSeguroEstado',
    ]),
    ...mapFields('formaPagoFacturaTercero', [
      'caja',
      'banco',
      'tarjeta',
    ]),
    ...mapFields('detalleFactura', [
      'totalSeguro',
    ]),
    showCortinaInformativa: {
      set(value) {
        this.setShowCortinaInformativa(value);
      },
      get() {
        return this.getShowCortinaInformativa;
      },
    },
    ...mapGetters('facturacion', [
      'getShowCortinaInformativa',
      'getPacienteToShow',
      'getCorrelativo',
    ]),
    ...mapGetters('formFactura', [
      'getDatePicker',
      'getPacienteSelected',
    ]),
    ...mapGetters('formaPagoModal', [
      'getInputDiasCredito',
      'isEnabledFormaPago',
    ]),
    ...mapGetters('detalleFactura', [
      'getSubTotal',
    ]),
  },
};
</script>

<style scoped>
.btn-white{
  background: white;
  color: black;
}
.bg-yellow-custom {
  background-color: #f9d772;
}
.bg-sky-light
{
  background:#a6d2eb;
}
.navbar-reubicado
{
  transform: translate(0px, -32px);
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.label-facturacion-tercero{
  line-height: 1.2;
  font-size: 11px;
}
</style>
